import {
  BaseQueryApi,
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import {
  getApiAccessToken,
  getApiLanguage,
  hasApiAccessToken,
  hasApiLanguage,
  isCloudApiEnabled,
} from 'core/apiConfig';
import { Config } from 'core/commonTypes';

export class RtkQueryConfig {
  private static singletonInstance: RtkQueryConfig;

  /**
   * Should be called during app initialization.
   *
   * @param config
   */
  static initialize(config: Config) {
    if (RtkQueryConfig.singletonInstance !== undefined) {
      throw new Error('RtkQueryConfig is already initialized');
    }
    RtkQueryConfig.singletonInstance = new RtkQueryConfig(config);
  }

  static get instance() {
    if (RtkQueryConfig.singletonInstance === undefined) {
      throw new Error('RtkQueryConfig is not initialized yet');
    }
    return RtkQueryConfig.singletonInstance;
  }

  private readonly cloudApi: string;

  private readonly integrationLayerApi: string;

  private readonly mhsEnabled: boolean;

  private constructor({ apiRoot, integrationRoot, mhsEnabled = true }: Config) {
    this.cloudApi = apiRoot;
    this.integrationLayerApi = mhsEnabled ? integrationRoot : this.cloudApi;
    this.mhsEnabled = mhsEnabled;
  }

  getApiUrl(method: string = 'GET', path: string = ''): string {
    return method === 'GET' ||
      path.match(/^\/calculations.+$/) ||
      isCloudApiEnabled()
      ? this.cloudApi
      : this.integrationLayerApi;
  }

  get isMhsEnabled(): boolean {
    return !this.isCloudEnabled;
  }

  get isCloudEnabled(): boolean {
    return !this.mhsEnabled || isCloudApiEnabled();
  }
}

type PathVariables = {
  [name: string]: string | number;
};

/**
 * Custom endpoint arguments. These can be populated
 */
type CustomEndpointArgs = {
  /**
   * Provides the path variables used in the `url`.
   *
   * The variable `buCode` is reserved and cannot be used.
   */
  pathVariables?: PathVariables;

  /**
   * Hard-coded data.
   *
   * This can be used to provide mock data or data for endpoints that are not backed by a backend API.
   */
  data?: any;
};

/**
 * Generates an absolute `/slm/backend` based on the currently-selected store.
 *
 * @param path Should start with a forward slash and contain the path variable `{buCode}`.
 * @param method HTTP method.
 * @param variables Optional path variables
 */
function apiUrl(
  path: string,
  method?: string,
  variables: PathVariables = {}
): string {
  if (!path.startsWith('/')) {
    throw new Error('Path prefix should start with forward slash');
  }

  Object.entries(variables).forEach(([name, value]) => {
    path = path.replace(`{${name}}`, encodeURIComponent(value));
  });

  return `${RtkQueryConfig.instance.getApiUrl(
    method,
    path
  )}/slm/backend${path}`;
}

type ApiQueryArgs = FetchArgs & CustomEndpointArgs;

/**
 * Produces an `slm-backend` query function that automatically populates `buCode`, based on the currently-selected store.
 * URLs passed to this query function must therefore contain a `{buCode}` path variable.
 *
 * The implementation allows for conditional fetching. When the request specifies a `url` of `null` the implementation
 * returns a resolved Promise with the `data` set to `null`. Conditional fetching is useful when invoking queries with
 * complex data dependencies, as React hooks cannot be invoked conditionally.
 *
 * In addition, the query function automatically adds an `Authorization` header containing the access token.
 *
 */
export function apiQuery(): BaseQueryFn<
  ApiQueryArgs,
  any,
  FetchBaseQueryError
> {
  const baseQuery = fetchBaseQuery({
    prepareHeaders: (headers: Headers) => {
      if (hasApiAccessToken()) {
        headers.set('Authorization', `Bearer ${getApiAccessToken()}`);
      }
      if (hasApiLanguage()) {
        headers.set('Accept-Language', getApiLanguage());
      }
    },
  });

  return async (args: ApiQueryArgs, api: BaseQueryApi, extraOptions: any) => {
    let { url, pathVariables, data, method } = args;
    if (data) {
      return Promise.resolve({ data });
    }

    // the buCode injected can be null sometimes, so we need to handle that
    if (url === null || pathVariables.buCode === null) {
      return Promise.resolve({ data: null });
    }

    url = apiUrl(url, method, pathVariables);

    return baseQuery({ ...args, url }, api, extraOptions);
  };
}

/**
 * Produces a `/user` query function.
 *
 * The query function enforces the user of an access token.
 */
export function userApiQuery() {
  const baseQuery = fetchBaseQuery({
    prepareHeaders: (headers: Headers) => {
      if (!hasApiAccessToken()) {
        throw new Error('Cannot invoke User API without access token');
      }
      headers.set('Authorization', `Bearer ${getApiAccessToken()}`);
    },
  });

  return (
    args: FetchArgs & CustomEndpointArgs,
    api: BaseQueryApi,
    extraOptions: any
  ) => {
    let { url, method } = args;

    if (url === null) {
      return Promise.resolve({ data: null });
    }

    url = `${RtkQueryConfig.instance.getApiUrl(method)}/user${url}`;

    return baseQuery({ ...args, url }, api, extraOptions);
  };
}
