import { DivisionDto, SpecialityShopDto } from 'apis/backendApi';
import { PendingUpdateEntity } from 'core/redux/pendingUpdatesSlice';

/**
 * The set of values that are updated by Divisions and Speciality Shops forms.
 */
export type StructureDetailsUpdateValues = {
  areaM2: number;
  plannedMaxDensity: number;
  plannedFillingRate: number;
};

/**
 * All details used by Divisions and Speciality Shops forms.
 */
export type StructureDetails = StructureDetailsUpdateValues & {
  salesSpaceQuantityVolume: number;
  maxDensity: number;
  plannedDensity: number;
};

/**
 * Creates a StructureDetails by extracting the properties from the DTO.
 */
export function createStructureDetails(
  dto: DivisionDto | SpecialityShopDto
): StructureDetails {
  return {
    salesSpaceQuantityVolume: dto.salesSpaceQuantityVolume,
    areaM2: dto.areaM2,
    maxDensity: dto.maxDensity,
    plannedMaxDensity: dto.plannedMaxDensity,
    plannedFillingRate: dto.plannedFillingRate,
    plannedDensity: dto.plannedDensity,
  };
}

export type StructureDetailsFormValues = {
  [name: string]: number;
};

export enum AssignerCrudPrefix {
  PA = 'ProductArea/',
  SLID = 'SalesLocation/',
}

export type PendingDivisionUpdate = {
  buCode: string;
  divisionId: string;
};

export function encodePendingDivisionUpdate(update: PendingDivisionUpdate) {
  const { buCode, divisionId } = update;
  if (typeof buCode !== 'string' || typeof divisionId !== 'string') {
    throw new Error('Invalid arguments');
  }

  return `division:/${buCode}/${divisionId}`;
}

function decodePendingDivisionUpdate(id: string): PendingDivisionUpdate {
  const match = id.match(/^division:\/(\d+)\/(\w{2})$/);
  if (match) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, buCode, divisionId] = match;
    if (typeof buCode !== 'string' || typeof divisionId !== 'string') {
      return undefined;
    }

    return {
      buCode,
      divisionId,
    };
  }
}

export function findPendingDivisionUpdate(
  entities: PendingUpdateEntity[],
  buCode: string,
  divisionId: string,
  lastUpdated?: string
) {
  return entities
    .filter(
      entity => lastUpdated === undefined || entity.lastUpdated === lastUpdated
    )
    .map(entity => decodePendingDivisionUpdate(entity.id))
    .find(
      update =>
        update !== undefined &&
        update.buCode === buCode &&
        update.divisionId === divisionId
    );
}

export type PendingSpecialityShopUpdate = {
  buCode: string;
  divisionId: string;
  specialityShopId: string;
};

export function encodePendingSpecialityShopUpdate(
  update: PendingSpecialityShopUpdate
) {
  const { buCode, divisionId, specialityShopId } = update;
  if (
    typeof buCode !== 'string' ||
    typeof divisionId !== 'string' ||
    typeof specialityShopId !== 'string'
  ) {
    throw new Error('Invalid arguments');
  }

  return `specialityShop:/${buCode}/${divisionId}/${specialityShopId}`;
}

function decodePendingSpecialityShopUpdate(
  id: string
): PendingSpecialityShopUpdate {
  const match = id.match(/^specialityShop:\/(\d+)\/(\w{2})\/(\w{6})$/);
  if (match) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, buCode, divisionId, specialityShopId] = match;
    if (
      typeof buCode !== 'string' ||
      (typeof divisionId !== 'string' && typeof specialityShopId !== 'string')
    ) {
      return undefined;
    }

    return {
      buCode,
      divisionId,
      specialityShopId,
    };
  }
}

export function findPendingSpecialityShopUpdate(
  entities: PendingUpdateEntity[],
  buCode: string,
  divisionId: string,
  specialityShopId: string,
  lastUpdated?: string
) {
  return entities
    .filter(
      entity => lastUpdated === undefined || entity.lastUpdated === lastUpdated
    )
    .map(entity => decodePendingSpecialityShopUpdate(entity.id))
    .find(
      update =>
        update !== undefined &&
        update.buCode === buCode &&
        update.divisionId === divisionId &&
        update.specialityShopId === specialityShopId
    );
}
