/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Unit Load Type values
 * @export
 */
export const UnitLoadTypeEnum = {
    UNIT_LOAD_TYPE_FULL_PALLET_EUROPE_1200X800_MM: 'A',
    UNIT_LOAD_TYPE_HALF_PALLET_EUROPE_600X800_MM: 'B',
    UNIT_LOAD_TYPE_LONG_PALLET_EUROPE_2000X800_MM: 'D',
    UNIT_LOAD_TYPE_FULL_PALLET_LOW_LIFTING_HM: 'AL',
    UNIT_LOAD_TYPE_HALF_PALLET_LOW_LIFTING_HM: 'BL',
    UNIT_LOAD_TYPE_LONG_PALLET_LOW_LIFTING_HM: 'DL',
    UNIT_LOAD_TYPE_BUNDLED: 'E',
    UNIT_LOAD_TYPE_UNPALLETIZED_GOODS_OR_MIXED_PALLET: 'F',
    UNIT_LOAD_TYPE_OTHER: 'G'
} as const;
export type UnitLoadTypeEnum = typeof UnitLoadTypeEnum[keyof typeof UnitLoadTypeEnum];


export function instanceOfUnitLoadTypeEnum(value: any): boolean {
    for (const key in UnitLoadTypeEnum) {
        if (Object.prototype.hasOwnProperty.call(UnitLoadTypeEnum, key)) {
            if (UnitLoadTypeEnum[key as keyof typeof UnitLoadTypeEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function UnitLoadTypeEnumFromJSON(json: any): UnitLoadTypeEnum {
    return UnitLoadTypeEnumFromJSONTyped(json, false);
}

export function UnitLoadTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnitLoadTypeEnum {
    return json as UnitLoadTypeEnum;
}

export function UnitLoadTypeEnumToJSON(value?: UnitLoadTypeEnum | null): any {
    return value as any;
}

export function UnitLoadTypeEnumToJSONTyped(value: any, ignoreDiscriminator: boolean): UnitLoadTypeEnum {
    return value as UnitLoadTypeEnum;
}

