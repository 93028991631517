/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const NextIndeliveryStatus = {
    PLANNED: 'PLANNED',
    CONFIRMED: 'CONFIRMED'
} as const;
export type NextIndeliveryStatus = typeof NextIndeliveryStatus[keyof typeof NextIndeliveryStatus];


export function instanceOfNextIndeliveryStatus(value: any): boolean {
    for (const key in NextIndeliveryStatus) {
        if (Object.prototype.hasOwnProperty.call(NextIndeliveryStatus, key)) {
            if (NextIndeliveryStatus[key as keyof typeof NextIndeliveryStatus] === value) {
                return true;
            }
        }
    }
    return false;
}

export function NextIndeliveryStatusFromJSON(json: any): NextIndeliveryStatus {
    return NextIndeliveryStatusFromJSONTyped(json, false);
}

export function NextIndeliveryStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): NextIndeliveryStatus {
    return json as NextIndeliveryStatus;
}

export function NextIndeliveryStatusToJSON(value?: NextIndeliveryStatus | null): any {
    return value as any;
}

export function NextIndeliveryStatusToJSONTyped(value: any, ignoreDiscriminator: boolean): NextIndeliveryStatus {
    return value as NextIndeliveryStatus;
}

