import { createApi } from '@reduxjs/toolkit/query/react';
import {
  DefaultParameterDto,
  DefaultParameterDtoFromJSON,
  HfbParameterDto,
  ParameterDto,
} from 'apis/backendApi';
import { addQueryContext, WithQueryContext } from 'core/apiQueryContext';
import { apiQuery } from 'core/rtkQuery';

import { applyUpdates } from 'views/Parameters/redux/types';

export type DivisionDefaults = {
  divisionId: string;
  parameters: ParameterDto[];
};

const GET_DEFAULT_PARAMETERS_ENDPOINT = 'getDefaultParameters';

const defaultParametersApiDef = createApi({
  reducerPath: 'defaultParametersApi',
  baseQuery: apiQuery(),

  endpoints: builder => ({
    [GET_DEFAULT_PARAMETERS_ENDPOINT]: builder.query<
      DefaultParameterDto,
      WithQueryContext<{}>
    >({
      query: ({ buCode }) => ({
        url: '/parameters/v1/{buCode}/defaults',
        pathVariables: { buCode },
      }),
      transformResponse: DefaultParameterDtoFromJSON,
    }),

    postStoreDefaultParameters: builder.mutation<
      void,
      WithQueryContext<{ parameters: ParameterDto[] }>
    >({
      query: ({ buCode, parameters }) => ({
        url: '/parameters/v1/{buCode}/defaults/store',
        pathVariables: { buCode },
        method: 'POST',
        body: { parameters },
      }),
      async onQueryStarted(
        { buCode, parameters },
        { dispatch, queryFulfilled }
      ) {
        await queryFulfilled;
        const { updateQueryData } = defaultParametersApi.util;
        dispatch(
          updateQueryData(
            GET_DEFAULT_PARAMETERS_ENDPOINT,
            { buCode },
            draft => {
              applyUpdates(parameters, draft.storeDefaults);
            }
          )
        );
      },
    }),

    postDivisionDefaultParameters: builder.mutation<
      void,
      WithQueryContext<DivisionDefaults>
    >({
      query: ({ buCode, divisionId, parameters }) => ({
        url: '/parameters/v1/{buCode}/defaults/division/{divisionId}',
        pathVariables: { buCode, divisionId },
        method: 'POST',
        body: parameters,
      }),
      async onQueryStarted(
        { buCode, divisionId, parameters },
        { dispatch, queryFulfilled }
      ) {
        await queryFulfilled;
        const { updateQueryData } = defaultParametersApi.util;
        dispatch(
          updateQueryData(
            GET_DEFAULT_PARAMETERS_ENDPOINT,
            { buCode },
            state => {
              const divisionParameters = state.divisions
                .filter(division => division.id === divisionId)
                .flatMap(division => division.parameters);
              applyUpdates(parameters, divisionParameters);
            }
          )
        );
      },
    }),

    postHfbDefaultParameters: builder.mutation<
      void,
      WithQueryContext<{ parameter: HfbParameterDto }>
    >({
      query: ({ buCode, parameter: hfb }) => ({
        url: '/parameters/v1/{buCode}/defaults/hfb',
        pathVariables: { buCode },
        method: 'POST',
        body: hfb,
      }),
      async onQueryStarted(
        { buCode, parameter: hfb },
        { dispatch, queryFulfilled }
      ) {
        await queryFulfilled;
        const { updateQueryData } = defaultParametersApi.util;
        dispatch(
          updateQueryData(
            GET_DEFAULT_PARAMETERS_ENDPOINT,
            { buCode },
            draft => {
              const target = draft.hfb.find(x => x.id === hfb.id);
              Object.assign(target, hfb);
            }
          )
        );
      },
    }),
  }),
});

export const defaultParametersApi = addQueryContext(defaultParametersApiDef);

export const {
  useGetDefaultParametersQuery,
  usePostStoreDefaultParametersMutation,
  usePostDivisionDefaultParametersMutation,
  usePostHfbDefaultParametersMutation,
} = defaultParametersApi;

export default defaultParametersApi;
