/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Indicates if article to be picked Manual or Automatic
 * @export
 */
export const PickLocationType = {
    Manual: 'M',
    Automatic: 'A'
} as const;
export type PickLocationType = typeof PickLocationType[keyof typeof PickLocationType];


export function instanceOfPickLocationType(value: any): boolean {
    for (const key in PickLocationType) {
        if (Object.prototype.hasOwnProperty.call(PickLocationType, key)) {
            if (PickLocationType[key as keyof typeof PickLocationType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function PickLocationTypeFromJSON(json: any): PickLocationType {
    return PickLocationTypeFromJSONTyped(json, false);
}

export function PickLocationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PickLocationType {
    return json as PickLocationType;
}

export function PickLocationTypeToJSON(value?: PickLocationType | null): any {
    return value as any;
}

export function PickLocationTypeToJSONTyped(value: any, ignoreDiscriminator: boolean): PickLocationType {
    return value as PickLocationType;
}

