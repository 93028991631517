import { createApi } from '@reduxjs/toolkit/query/react';
import { apiQuery } from 'core/rtkQuery';
import {
  GetItemSearchRequest,
  GetItemSearchNavigationInfoRequest,
  PaginatedItemSearchResultDto,
  ItemSearchNavigationInfoDto,
} from 'apis/backendApi';
import { addQueryContext, WithQueryContext } from 'core/apiQueryContext';

const queryValuesWithoutNull = (args: GetItemSearchRequest) => {
  return Object.entries(args)
    .filter(([k, v]) => k !== 'buCode' && !!v)
    .reduce((acc, [k, v]) => {
      acc[k] = v;
      return acc;
    }, {});
};
const searchApiDef = createApi({
  reducerPath: 'searchApi',
  baseQuery: apiQuery(),

  endpoints: build => ({
    getItemSearch: build.query<
      PaginatedItemSearchResultDto,
      WithQueryContext<GetItemSearchRequest>
    >({
      query: args => {
        return {
          url: '/items/v1/{buCode}/search',
          pathVariables: { buCode: args.buCode },
          params: queryValuesWithoutNull(args),
        };
      },
    }),

    getItemSearchNavigationInfo: build.query<
      ItemSearchNavigationInfoDto,
      WithQueryContext<GetItemSearchNavigationInfoRequest>
    >({
      query: args => {
        return {
          url: '/items/v1/{buCode}/search-navigation-info',
          pathVariables: { buCode: args.buCode },
          params: queryValuesWithoutNull(args),
        };
      },
    }),

    // summary: TODO
  }),
});

const searchApi = addQueryContext(searchApiDef);

export const { useGetItemSearchQuery, useGetItemSearchNavigationInfoQuery } =
  searchApi;

export default searchApi;
