import { createApi } from '@reduxjs/toolkit/query/react';
import { apiQuery } from 'core/rtkQuery';
import {
  DivisionDto,
  IdDto,
  OverallStoreStructureDto,
  OverallStoreStructureDtoFromJSON,
  ProductAreaDto,
  RangeGroupDto,
  RangeNodeDto,
  RangeNodeDtoFromJSON,
  SalesLocationDto,
  SalesLocationDtoToJSON,
  SpecialityShopDto,
} from 'apis/backendApi';
import {
  endOperation,
  startCreate,
  startDelete,
  startUpdate,
} from 'core/redux/crudOperationsSlice';
import {
  OperationType,
  storeStructureOperationId,
  StoreStructureType,
} from 'core/commonTypes';
import {
  AssignerCrudPrefix,
  encodePendingDivisionUpdate,
  encodePendingSpecialityShopUpdate,
  findPendingDivisionUpdate,
  findPendingSpecialityShopUpdate,
} from 'views/StoreStructure/types';
import {
  selectPendingUpdates,
  startPendingUpdate,
  syncPendingUpdates,
} from 'core/redux/pendingUpdatesSlice';
import { RootState } from 'core/store';
import { WithQueryContext, addQueryContext } from 'core/apiQueryContext';

const PLACEHOLDER_DIVISION: DivisionDto = {
  buCode: '1',
  id: 'PH',
  name: 'Placeholder Division',
  areaM2: 0,
  plannedFillingRate: 0,
  plannedMaxDensity: 0,
  specialityShops: [
    {
      id: 'SH',
      divId: 'PH',
      name: 'Placeholder Speciality Shop',
      type: 'L',
      hidden: false,
      plannedMaxDensity: 0,
      plannedFillingRate: 0,
      areaM2: 0,
    },
  ],
};

const GET_STORE_STRUCTURE_TREE_ENDPOINT = 'getStoreStructureTree';

const structuresApiDef = createApi({
  reducerPath: 'structuresApi',
  baseQuery: apiQuery(),

  endpoints: builder => {
    return {
      [GET_STORE_STRUCTURE_TREE_ENDPOINT]: builder.query<
        OverallStoreStructureDto,
        WithQueryContext<{}>
      >({
        query: ({ buCode }) => ({
          url: '/structures/v1/{buCode}/storestructuretree',
          pathVariables: { buCode },
        }),

        transformResponse: (json: any) => {
          const dto = OverallStoreStructureDtoFromJSON(json);
          if (dto.divisions.length === 0) {
            // An empty division array should not occur under normal circumstances.
            // To avoid breaking components that assume non-empty Divisions, we use a placeholder.
            dto.divisions = [PLACEHOLDER_DIVISION];
          }
          return dto;
        },
      }),

      getRangeStructureTree: builder.query<
        RangeNodeDto[],
        WithQueryContext<{}>
      >({
        query({ buCode }) {
          return {
            url: '/structures/v1/{buCode}/rangestructuretree',
            pathVariables: { buCode },
          };
        },

        transformResponse(json: any) {
          return json?.map(RangeNodeDtoFromJSON);
        },
      }),

      getStoreStructureDivision: builder.query<
        DivisionDto,
        WithQueryContext<{ buCode: string; divId: string }>
      >({
        query: ({ buCode, divId }) => ({
          url: '/structures/v1/{buCode}/division/{divId}',
          pathVariables: {
            buCode,
            divId,
          },
        }),

        async onQueryStarted(
          { buCode, divId: divisionId },
          { dispatch, getState, queryFulfilled }
        ) {
          const { data } = await queryFulfilled;

          const lastUpdated = {
            [encodePendingDivisionUpdate({ buCode, divisionId })]:
              data.lastUpdated,
          };
          dispatch(syncPendingUpdates({ lastUpdated, prefix: 'division' }));

          const state = getState() as RootState;
          const update = findPendingDivisionUpdate(
            selectPendingUpdates(state.pendingUpdates),
            divisionId,
            data.lastUpdated
          );
          if (update) {
            // No change
            return;
          }

          const { updateQueryData } = structuresApi.util;
          dispatch(
            updateQueryData(
              GET_STORE_STRUCTURE_TREE_ENDPOINT,
              { buCode },
              state => {
                const division = state.divisions.find(
                  division => division.id === divisionId
                );
                // Update only the calculated fields
                const { salesSpaceQuantityVolume, maxDensity, plannedDensity } =
                  data;
                Object.assign(division, {
                  salesSpaceQuantityVolume,
                  maxDensity,
                  plannedDensity,
                });
              }
            )
          );
        },

        keepUnusedDataFor: 0,
      }),

      putStoreStructureDivision: builder.mutation<
        DivisionDto,
        WithQueryContext<{ dto: DivisionDto }>
      >({
        query: ({ dto, buCode }) => {
          return {
            url: '/structures/v1/{buCode}/division/{divId}',
            pathVariables: { divId: dto.id, buCode },
            method: 'PUT',
            body: dto,
          };
        },

        async onQueryStarted({ buCode, dto }, { dispatch, queryFulfilled }) {
          const { updateQueryData } = structuresApi.util;
          const patch = dispatch(
            updateQueryData(
              GET_STORE_STRUCTURE_TREE_ENDPOINT,
              { buCode },
              state => {
                const division = state.divisions.find(
                  division => division.id === dto.id
                );
                Object.assign(division, dto);
              }
            )
          );

          const operationId = storeStructureOperationId(
            StoreStructureType.Division,
            dto.id
          );
          dispatch(startUpdate(operationId));
          try {
            const { data } = await queryFulfilled;
            dispatch(
              startPendingUpdate({
                id: encodePendingDivisionUpdate({
                  buCode,
                  divisionId: dto.id,
                }),
                type: OperationType.UPDATE,
                lastUpdated: dto.lastUpdated,
              })
            );
            dispatch(
              updateQueryData(
                GET_STORE_STRUCTURE_TREE_ENDPOINT,
                { buCode },
                state => {
                  const division = state.divisions.find(
                    division => division.id === dto.id
                  );
                  const {
                    plannedDensity,
                    maxDensity,
                    salesSpaceQuantityVolume,
                  } = data;
                  Object.assign(division, {
                    salesSpaceQuantityVolume,
                    maxDensity,
                    plannedDensity,
                  });
                }
              )
            );
          } catch {
            patch.undo();
          } finally {
            dispatch(endOperation(operationId));
          }
        },
      }),

      getStoreStructureSpeciality: builder.query<
        SpecialityShopDto,
        WithQueryContext<{ divId: string; spId: string }>
      >({
        query: ({ buCode, divId, spId }) => ({
          url: '/structures/v1/{buCode}/division/{divId}/specialityShop/{spId}',
          pathVariables: { buCode, divId, spId },
        }),

        async onQueryStarted(
          { buCode, divId: divisionId, spId: specialityShopId },
          { dispatch, getState, queryFulfilled }
        ) {
          const { data } = await queryFulfilled;

          const lastUpdated = {
            [encodePendingSpecialityShopUpdate({
              buCode,
              divisionId,
              specialityShopId,
            })]: data.lastUpdated,
          };
          dispatch(
            syncPendingUpdates({ lastUpdated, prefix: 'specialityShop' })
          );

          const state = getState() as RootState;
          const update = findPendingSpecialityShopUpdate(
            selectPendingUpdates(state.pendingUpdates),
            divisionId,
            specialityShopId,
            data.lastUpdated
          );
          if (update) {
            // No change
            return;
          }

          const { updateQueryData } = structuresApi.util;
          dispatch(
            updateQueryData(
              GET_STORE_STRUCTURE_TREE_ENDPOINT,
              { buCode },
              state => {
                const specialityShop = state.divisions
                  .find(division => division.id === divisionId)
                  ?.specialityShops?.find(
                    specialityShop => specialityShop.id === specialityShopId
                  );
                // Update only the calculated fields
                const { salesSpaceQuantityVolume, maxDensity, plannedDensity } =
                  data;
                Object.assign(specialityShop, {
                  salesSpaceQuantityVolume,
                  maxDensity,
                  plannedDensity,
                });
              }
            )
          );
        },
      }),

      putStoreStructureSpeciality: builder.mutation<
        void,
        WithQueryContext<{ dto: SpecialityShopDto; tags?: string[] }>
      >({
        query: ({ buCode, dto, tags = [] }) => {
          const [tag] = tags;
          return {
            url: '/structures/v1/{buCode}/division/{divId}/specialityShop/{spId}',
            pathVariables: { buCode, divId: dto.divId, spId: dto.id },
            params: { visibility: tag === 'hidden' },
            method: 'PUT',
            body: dto,
          };
        },

        async onQueryStarted(
          { buCode, dto, tags = [] },
          { dispatch, queryFulfilled }
        ) {
          const { updateQueryData } = structuresApi.util;
          const patch = dispatch(
            updateQueryData(
              GET_STORE_STRUCTURE_TREE_ENDPOINT,
              { buCode },
              state => {
                const specialityShop = state.divisions
                  .find(division => division.id === dto.divId)
                  ?.specialityShops?.find(
                    specialityShop => specialityShop.id === dto.id
                  );
                Object.assign(specialityShop, dto);
              }
            )
          );
          const operationId = storeStructureOperationId(
            StoreStructureType.SpecialityShop,
            dto.id
          );
          dispatch(startUpdate(operationId, tags));

          try {
            const { data: updatedDto } = await queryFulfilled;

            dispatch(
              startPendingUpdate({
                id: encodePendingSpecialityShopUpdate({
                  buCode,
                  divisionId: dto.divId,
                  specialityShopId: dto.id,
                }),
                type: OperationType.UPDATE,
                lastUpdated: dto.lastUpdated,
              })
            );

            dispatch(
              updateQueryData(
                GET_STORE_STRUCTURE_TREE_ENDPOINT,
                { buCode },
                state => {
                  const specialityShop = state.divisions
                    .find(division => division.id === dto.divId)
                    ?.specialityShops?.find(
                      specialityShop => specialityShop.id === dto.id
                    );
                  const {
                    salesSpaceQuantityVolume,
                    maxDensity,
                    plannedDensity,
                  } = updatedDto as unknown as SpecialityShopDto;
                  Object.assign(specialityShop, {
                    salesSpaceQuantityVolume,
                    maxDensity,
                    plannedDensity,
                  });
                }
              )
            );
          } catch (e) {
            console.dir(e);
            patch.undo();
          } finally {
            dispatch(endOperation(operationId, tags));
          }
        },
      }),

      postStoreStructureSpeciality: builder.mutation<
        IdDto,
        WithQueryContext<{ dto: SpecialityShopDto }>
      >({
        query: ({ buCode, dto }) => ({
          url: '/structures/v1/{buCode}/division/{divId}/specialityShop/',
          pathVariables: { buCode, divId: dto.divId },
          method: 'POST',
          body: dto,
        }),

        async onQueryStarted(
          { buCode, dto: specialityShopDto },
          { dispatch, queryFulfilled }
        ) {
          const operationId = storeStructureOperationId(
            StoreStructureType.SpecialityShop,
            specialityShopDto.id
          );
          dispatch(startCreate(operationId));

          try {
            const { data } = await queryFulfilled;
            const { updateQueryData } = structuresApi.util;
            dispatch(
              updateQueryData(
                GET_STORE_STRUCTURE_TREE_ENDPOINT,
                { buCode },
                state => {
                  state.divisions
                    .find(division => division.id === specialityShopDto.divId)
                    .specialityShops.push({
                      ...specialityShopDto,
                      id: data.id,
                    });
                }
              )
            );
          } catch (e) {
          } finally {
            dispatch(endOperation(operationId));
          }
        },
      }),

      deleteStoreStructureSpeciality: builder.mutation<
        void,
        WithQueryContext<{
          divisionId: string;
          specialityShopId: string;
          name?: string;
        }>
      >({
        query: ({ buCode, divisionId, specialityShopId, name }) => ({
          url: '/structures/v1/{buCode}/division/{divId}/specialityShop/{spId}',
          pathVariables: { buCode, divId: divisionId, spId: specialityShopId },
          params: { name },
          method: 'DELETE',
        }),

        async onQueryStarted(
          { buCode, divisionId, specialityShopId },
          { dispatch, queryFulfilled }
        ) {
          const operationId = storeStructureOperationId(
            StoreStructureType.SpecialityShop,
            specialityShopId
          );
          dispatch(startDelete(operationId));

          try {
            await queryFulfilled;
            const { updateQueryData } = structuresApi.util;
            dispatch(
              updateQueryData(
                GET_STORE_STRUCTURE_TREE_ENDPOINT,
                { buCode },
                state => {
                  const division = state.divisions.find(
                    division => division.id === divisionId
                  );
                  division.specialityShops = [
                    ...division.specialityShops.filter(
                      specialityShop => specialityShop.id !== specialityShopId
                    ),
                  ];
                }
              )
            );
          } catch {
          } finally {
            dispatch(endOperation(operationId));
          }
        },
      }),

      putStoreStructureRangeGroup: builder.mutation<
        void,
        WithQueryContext<{
          divisionId: string;
          dto: RangeGroupDto;
          tags?: string[];
        }>
      >({
        query: ({ buCode, divisionId, dto, tags }) => {
          const [tag] = tags;
          return {
            url: '/structures/v1/{buCode}/division/{divId}/specialityShop/{spId}/rangeGroup/{rgId}',
            pathVariables: {
              buCode,
              divId: divisionId,
              spId: dto.specialityShopId,
              rgId: dto.id,
            },
            params: { visibility: tag === 'hidden' },
            method: 'PUT',
            body: dto,
          };
        },

        async onQueryStarted(
          { buCode, divisionId, dto, tags = [] },
          { dispatch, queryFulfilled }
        ) {
          const operationId = storeStructureOperationId(
            StoreStructureType.RangeGroup,
            dto.id
          );
          dispatch(startUpdate(operationId, tags));

          try {
            await queryFulfilled;
            const { updateQueryData } = structuresApi.util;
            dispatch(
              updateQueryData(
                GET_STORE_STRUCTURE_TREE_ENDPOINT,
                { buCode },
                state => {
                  const rangeGroup = state.divisions
                    .find(division => division.id === divisionId)
                    ?.specialityShops?.find(
                      specialityShop =>
                        specialityShop.id === dto.specialityShopId
                    )
                    ?.rangeGroups.find(rangeGroup => rangeGroup.id === dto.id);
                  Object.assign(rangeGroup, dto);
                }
              )
            );
          } catch {
          } finally {
            dispatch(endOperation(operationId, tags));
          }
        },
      }),

      postStoreStructureRangeGroup: builder.mutation<
        IdDto,
        WithQueryContext<{ divisionId: string; dto: RangeGroupDto }>
      >({
        query: ({ buCode, divisionId, dto }) => ({
          url: '/structures/v1/{buCode}/division/{divId}/specialityShop/{spId}/rangeGroup',
          pathVariables: {
            buCode,
            divId: divisionId,
            spId: dto.specialityShopId,
          },
          method: 'POST',
          body: dto,
        }),

        async onQueryStarted(
          { buCode, divisionId, dto: rangeGroupDto },
          { dispatch, queryFulfilled }
        ) {
          const operationId = storeStructureOperationId(
            StoreStructureType.RangeGroup,
            rangeGroupDto.id
          );
          dispatch(startCreate(operationId));

          try {
            const { data } = await queryFulfilled;
            const { updateQueryData } = structuresApi.util;
            dispatch(
              updateQueryData(
                GET_STORE_STRUCTURE_TREE_ENDPOINT,
                { buCode },
                state => {
                  state.divisions
                    .find(division => division.id === divisionId)
                    .specialityShops.find(
                      specialityShop =>
                        specialityShop.id === rangeGroupDto.specialityShopId
                    )
                    .rangeGroups.push({
                      ...rangeGroupDto,
                      id: data.id,
                    });
                }
              )
            );
          } catch {
          } finally {
            dispatch(endOperation(operationId));
          }
        },
      }),

      deleteStoreStructureRangeGroup: builder.mutation<
        void,
        WithQueryContext<{
          divisionId: string;
          specialityShopId: string;
          rangeGroupId: string;
          description?: string;
        }>
      >({
        query({
          buCode,
          divisionId,
          specialityShopId,
          rangeGroupId,
          description,
        }) {
          return {
            url: '/structures/v1/{buCode}/division/{divId}/specialityShop/{spId}/rangeGroup/{rgId}',
            pathVariables: {
              buCode,
              divId: divisionId,
              spId: specialityShopId,
              rgId: rangeGroupId,
            },
            params: { description },
            method: 'DELETE',
          };
        },

        async onQueryStarted(
          { buCode, divisionId, specialityShopId, rangeGroupId },
          { dispatch, queryFulfilled }
        ) {
          const operationId = storeStructureOperationId(
            StoreStructureType.RangeGroup,
            rangeGroupId
          );
          dispatch(startDelete(operationId));

          try {
            await queryFulfilled;
            const { updateQueryData } = structuresApi.util;
            dispatch(
              updateQueryData(
                GET_STORE_STRUCTURE_TREE_ENDPOINT,
                { buCode },
                state => {
                  const specialityShop = state.divisions
                    .find(division => division.id === divisionId)
                    ?.specialityShops.find(
                      specialityShop => specialityShop.id === specialityShopId
                    );
                  specialityShop.rangeGroups = [
                    ...specialityShop.rangeGroups.filter(
                      rangeGroup => rangeGroup.id !== rangeGroupId
                    ),
                  ];
                }
              )
            );
          } catch {
          } finally {
            dispatch(endOperation(operationId));
          }
        },
      }),

      getProductArea: builder.query<
        ProductAreaDto[],
        WithQueryContext<{ rgid: string }>
      >({
        query: ({ rgid, buCode }) => ({
          url: '/structures/v1/{buCode}/productArea',
          pathVariables: { buCode },
          params: {
            buCode,
            rgid,
          },
        }),
        keepUnusedDataFor: 0,
      }),

      postProductArea: builder.mutation<
        void,
        WithQueryContext<{ pa: ProductAreaDto; rgid: string }>
      >({
        query: ({ buCode, pa, rgid }) => ({
          url: '/structures/v1/{buCode}/productArea/{id}/rangeGroupId',
          pathVariables: { buCode, id: pa.id },
          method: 'POST',
          body: rgid,
        }),

        async onQueryStarted(
          { buCode, pa, rgid },
          { dispatch, queryFulfilled }
        ) {
          const operationId = `${AssignerCrudPrefix.PA}${pa.id}`;
          dispatch(startUpdate(operationId, ['assign']));
          try {
            await queryFulfilled;
            const { updateQueryData } = structuresApi.util;
            dispatch(
              updateQueryData('getProductArea', { buCode, rgid }, draft => {
                draft.push(pa);
              })
            );
            dispatch(
              updateQueryData('getProductArea', { buCode, rgid: '' }, draft => {
                draft.splice(
                  draft.findIndex(loopPa => loopPa.id === pa.id),
                  1
                );
              })
            );
          } catch {
          } finally {
            dispatch(endOperation(operationId));
          }
        },
      }),

      deleteProductArea: builder.mutation<
        void,
        WithQueryContext<{ pa: ProductAreaDto; rgid: string }>
      >({
        query: ({ buCode, pa, rgid }) => ({
          url: '/structures/v1/{buCode}/productArea/{id}/rangeGroupId',
          pathVariables: { buCode, id: pa.id },
          params: {
            rgid,
          },
          method: 'DELETE',
        }),

        async onQueryStarted(
          { buCode, pa, rgid },
          { dispatch, queryFulfilled }
        ) {
          const operationId = `${AssignerCrudPrefix.PA}${pa.id}`;
          dispatch(startUpdate(operationId, ['assign']));
          try {
            await queryFulfilled;
            const { updateQueryData } = structuresApi.util;
            dispatch(
              updateQueryData('getProductArea', { buCode, rgid }, draft => {
                draft.splice(
                  draft.findIndex(loopPa => loopPa.id === pa.id),
                  1
                );
              })
            );
            dispatch(
              updateQueryData('getProductArea', { buCode, rgid: '' }, draft => {
                draft.push(pa);
              })
            );
          } catch {
          } finally {
            dispatch(endOperation(operationId));
          }
        },
      }),

      getSalesLocation: builder.query<
        SalesLocationDto[],
        WithQueryContext<{ rgid: string }>
      >({
        query: ({ buCode, rgid }) => ({
          url: '/structures/v1/{buCode}/salesLocation',
          pathVariables: { buCode },
          params: { rgid },
        }),
        keepUnusedDataFor: 0,
      }),

      putSalesLocation: builder.mutation<
        void,
        WithQueryContext<{
          dto: SalesLocationDto;
          cacheRangeGroupId: string;
          tag: string;
        }>
      >({
        query: ({ buCode, dto, tag }) => ({
          url: '/structures/v1/{buCode}/salesLocation',
          method: 'PUT',
          body: dto,
          pathVariables: { buCode },
          params: {
            visibility: tag === 'visibility',
          },
        }),

        async onQueryStarted(
          { buCode, dto, cacheRangeGroupId, tag },
          { dispatch, queryFulfilled }
        ) {
          const operationId = `${AssignerCrudPrefix.SLID}${dto.id}`;
          dispatch(startUpdate(operationId, [tag]));
          try {
            await queryFulfilled;
            const { updateQueryData } = structuresApi.util;
            if (dto.rangeGroup === cacheRangeGroupId) {
              // SLID updated, update cache in place
              dispatch(
                updateQueryData(
                  'getSalesLocation',
                  { buCode, rgid: dto.rangeGroup },
                  draft => {
                    draft.splice(
                      draft.findIndex(loopPa => loopPa.id === dto.id),
                      1,
                      dto
                    );
                  }
                )
              );
            } else {
              // SLID range ID value updated, swap DTO place in cache
              dispatch(
                updateQueryData(
                  'getSalesLocation',
                  { buCode, rgid: cacheRangeGroupId },
                  draft => {
                    draft.splice(
                      draft.findIndex(loopPa => loopPa.id === dto.id),
                      1
                    );
                  }
                )
              );
              dispatch(
                updateQueryData(
                  'getSalesLocation',
                  { buCode, rgid: dto.rangeGroup },
                  draft => {
                    draft.push(dto);
                  }
                )
              );
            }
          } catch {
          } finally {
            dispatch(endOperation(operationId));
          }
        },
      }),

      deleteSalesLocation: builder.mutation<
        void,
        WithQueryContext<{ id: string; cacheRangeGroupId: string }>
      >({
        query: ({ buCode, id }) => ({
          url: '/structures/v1/{buCode}/salesLocation/{id}',
          pathVariables: {
            buCode,
            id,
          },
          method: 'DELETE',
        }),
        async onQueryStarted(
          { buCode, id, cacheRangeGroupId },
          { dispatch, queryFulfilled }
        ) {
          const operationId = `${AssignerCrudPrefix.SLID}${id}`;
          dispatch(startDelete(operationId, ['delete']));
          try {
            await queryFulfilled;
            const { updateQueryData } = structuresApi.util;
            dispatch(
              updateQueryData(
                'getSalesLocation',
                { buCode, rgid: cacheRangeGroupId },
                draft => {
                  draft.splice(
                    draft.findIndex(loopPa => loopPa.id === id),
                    1
                  );
                }
              )
            );
          } catch {
          } finally {
            dispatch(endOperation(operationId));
          }
        },
      }),

      postSalesLocation: builder.mutation<
        void,
        WithQueryContext<{ dto: SalesLocationDto }>
      >({
        query: ({ buCode, dto }) => ({
          url: '/structures/v1/{buCode}/salesLocation',
          pathVariables: { buCode },
          method: 'POST',
          body: SalesLocationDtoToJSON(dto),
        }),

        async onQueryStarted({ buCode, dto }, { dispatch, queryFulfilled }) {
          await queryFulfilled;
          const { updateQueryData } = structuresApi.util;
          dispatch(
            updateQueryData('getSalesLocation', { buCode, rgid: '' }, draft => {
              draft.push(dto);
            })
          );
        },
      }),
    };
  },
});

export const structuresApi = addQueryContext(structuresApiDef);

structuresApi.enhanceEndpoints({});

export const {
  useGetRangeStructureTreeQuery,
  useGetStoreStructureDivisionQuery,
  useGetStoreStructureSpecialityQuery,
  useGetStoreStructureTreeQuery,
  useGetProductAreaQuery,
  useGetSalesLocationQuery,
  usePutStoreStructureDivisionMutation,
  usePostStoreStructureSpecialityMutation,
  usePutStoreStructureSpecialityMutation,
  useDeleteStoreStructureSpecialityMutation,
  usePostStoreStructureRangeGroupMutation,
  usePutStoreStructureRangeGroupMutation,
  useDeleteStoreStructureRangeGroupMutation,
  usePostProductAreaMutation,
  useDeleteProductAreaMutation,
  usePutSalesLocationMutation,
  useDeleteSalesLocationMutation,
  usePostSalesLocationMutation,
} = structuresApi;

export default structuresApi;
