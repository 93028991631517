import {
  QueryContextState,
  selectApiQueryContext,
} from 'core/redux/apiQueryContextSlice';
import { useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';

const LAST_SELECTED_BU_CODE = 'slm.lastSelectedBuCode';

/**
 * Obtains the buCode from state.
 *
 * @return The buCode or `null` if the current buCode has never been set.
 */
export function useBuCode(): QueryContextState['buCode'] {
  return useSelector(selectApiQueryContext).buCode;
}

/**
 * Manages buCode selection.
 */
export function useBuCodeSelection() {
  const buCode = useRef<string>(localStorage.getItem(LAST_SELECTED_BU_CODE));

  const saveSelectedBuCode = useCallback((buCode: string) => {
    if (buCode !== null) {
      localStorage.setItem(LAST_SELECTED_BU_CODE, buCode);
    }
  }, []);

  return {
    lastSelectedBuCode: buCode.current,
    saveSelectedBuCode,
  };
}
