import { combineReducers } from '@reduxjs/toolkit';
import defaultParametersApi from 'views/Parameters/redux/defaultParametersApi';
import parameterApprovalSlice from 'views/Parameters/redux/parameterApprovalSlice';
import parameterComputationSlice from 'views/Parameters/redux/parameterComputationSlice';
import { structuresApi } from 'views/StoreStructure/redux/structuresApi';
import crudOperationsSlice from 'core/redux/crudOperationsSlice';
import searchParamsSlice from 'core/redux/searchParamsSlice';
import f6ParametersApi from 'views/Parameters/redux/f6ParametersApi';
import parameterLimitsApi from 'views/Parameters/redux/parameterLimitsApi';
import articleParametersApi from 'views/Parameters/redux/articleParametersApi';
import groupParametersApi from 'views/Parameters/redux/groupParametersApi';
import parameterHistoryApi from 'views/Parameters/redux/parameterHistoryApi';
import userApi from 'core/redux/userApi';
import reportsApi from 'views/Summaries/redux/reportsApi';
import searchApi from 'views/Maintain/redux/searchApi';
import pendingUpdatesSlice from 'core/redux/pendingUpdatesSlice';
import userPreferencesSlice from 'core/redux/userPreferencesSlice';
import itemApi from 'views/Maintain/redux/itemApi';
import actionsApi from 'views/Actions/redux/actionsApi';
import utilityApi from 'views/DevTools/redux/utilityApi';
import importSlice from 'views/Import/redux/importSlice';
import exportSlice from 'views/Import/redux/exportSlice';
import storeMetadataApi from 'core/redux/storeMetadataApi';
import apiQueryContextSlice from 'core/redux/apiQueryContextSlice';

const rootReducer = combineReducers({
  [apiQueryContextSlice.name]: apiQueryContextSlice.reducer,
  searchParams: searchParamsSlice.reducer,
  crudOperations: crudOperationsSlice.reducer,
  pendingUpdates: pendingUpdatesSlice.reducer,
  userPreferences: userPreferencesSlice.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [structuresApi.reducerPath]: structuresApi.reducer,
  [actionsApi.reducerPath]: actionsApi.reducer,
  [parameterLimitsApi.reducerPath]: parameterLimitsApi.reducer,
  [parameterHistoryApi.reducerPath]: parameterHistoryApi.reducer,
  [defaultParametersApi.reducerPath]: defaultParametersApi.reducer,
  [groupParametersApi.reducerPath]: groupParametersApi.reducer,
  [articleParametersApi.reducerPath]: articleParametersApi.reducer,
  [f6ParametersApi.reducerPath]: f6ParametersApi.reducer,
  [reportsApi.reducerPath]: reportsApi.reducer,
  [searchApi.reducerPath]: searchApi.reducer,
  [itemApi.reducerPath]: itemApi.reducer,
  [utilityApi.reducerPath]: utilityApi.reducer,
  [storeMetadataApi.reducerPath]: storeMetadataApi.reducer,
  criticalParameters: parameterApprovalSlice.reducer,
  computedParameters: parameterComputationSlice.reducer,
  [importSlice.reducerPath]: importSlice.reducer,
  [exportSlice.reducerPath]: exportSlice.reducer,
});

export default rootReducer;
