/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CommercialClassEnum = {
    COMMERCIAL_CLASS_UNSPECIFIED: 'UNSPECIFIED',
    COMMERCIAL_CLASS_ACT: 'ACT',
    COMMERCIAL_CLASS_ACTB: 'ACTB',
    COMMERCIAL_CLASS_ACTM: 'ACTM',
    COMMERCIAL_CLASS_ADM: 'ADM',
    COMMERCIAL_CLASS_BAS: 'BAS',
    COMMERCIAL_CLASS_DET: 'DET',
    COMMERCIAL_CLASS_EXTRA: 'EXTRA',
    COMMERCIAL_CLASS_GLH: 'GLH',
    COMMERCIAL_CLASS_MAN: 'MAN',
    COMMERCIAL_CLASS_OPT: 'OPT',
    COMMERCIAL_CLASS_SACTB: 'SACTB',
    COMMERCIAL_CLASS_SBAS: 'SBAS',
    COMMERCIAL_CLASS_SEAS: 'SEAS',
    COMMERCIAL_CLASS_STO: 'STO',
    COMMERCIAL_CLASS_TEST: 'TEST',
    COMMERCIAL_CLASS_UTG: 'UTG'
} as const;
export type CommercialClassEnum = typeof CommercialClassEnum[keyof typeof CommercialClassEnum];


export function instanceOfCommercialClassEnum(value: any): boolean {
    for (const key in CommercialClassEnum) {
        if (Object.prototype.hasOwnProperty.call(CommercialClassEnum, key)) {
            if (CommercialClassEnum[key as keyof typeof CommercialClassEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function CommercialClassEnumFromJSON(json: any): CommercialClassEnum {
    return CommercialClassEnumFromJSONTyped(json, false);
}

export function CommercialClassEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommercialClassEnum {
    return json as CommercialClassEnum;
}

export function CommercialClassEnumToJSON(value?: CommercialClassEnum | null): any {
    return value as any;
}

export function CommercialClassEnumToJSONTyped(value: any, ignoreDiscriminator: boolean): CommercialClassEnum {
    return value as CommercialClassEnum;
}

