import { createApi } from '@reduxjs/toolkit/query/react';
import { apiQuery } from 'core/rtkQuery';
import { ItemParameterDto, ParameterDto } from 'apis/backendApi';
import { applyUpdates } from 'views/Parameters/redux/types';
import { addQueryContext, WithQueryContext } from 'core/apiQueryContext';

const GET_PARAMETERS_FOR_SINGLE_ITEM = 'getParametersForSingleItem';

type ItemParameters = {
  itemNo: string;
  parameters: ParameterDto[];
  refetch: boolean;
};

const articleParametersApiDef = createApi({
  reducerPath: 'articleParametersApi',
  baseQuery: apiQuery(),

  tagTypes: ['article'],

  endpoints: builder => ({
    [GET_PARAMETERS_FOR_SINGLE_ITEM]: builder.query<
      ItemParameterDto,
      WithQueryContext<{ articleNumber: string | null }>
    >({
      query: ({ buCode, articleNumber }) => ({
        url:
          articleNumber !== null
            ? '/parameters/v1/{buCode}/item/{itemNo}'
            : null,
        pathVariables: { buCode, itemNo: articleNumber },
      }),

      keepUnusedDataFor: 0,

      providesTags: (result, error, { articleNumber }) => [
        { type: 'article', id: articleNumber },
      ],
    }),

    postParametersForSingleItem: builder.mutation<
      void,
      WithQueryContext<ItemParameters>
    >({
      query: ({ buCode, itemNo, parameters }) => ({
        url: '/parameters/v1/{buCode}/item/{itemNo}',
        pathVariables: { buCode, itemNo },
        method: 'POST',
        body: parameters,
      }),

      async onQueryStarted(
        { buCode, itemNo, parameters, refetch },
        { dispatch, queryFulfilled }
      ) {
        await queryFulfilled;

        const { updateQueryData, invalidateTags } = articleParametersApi.util;
        if (refetch) {
          dispatch(invalidateTags([{ type: 'article', id: itemNo }]));
        } else {
          dispatch(
            updateQueryData(
              GET_PARAMETERS_FOR_SINGLE_ITEM,
              { buCode, articleNumber: itemNo },
              draft => {
                applyUpdates(parameters, draft.parameters);
              }
            )
          );
        }
      },
    }),
  }),
});

const articleParametersApi = addQueryContext(articleParametersApiDef);

export const {
  useGetParametersForSingleItemQuery,
  usePostParametersForSingleItemMutation,
} = articleParametersApi;

export default articleParametersApi;
