import { createApi } from '@reduxjs/toolkit/query/react';
import { apiQuery } from 'core/rtkQuery';
import {
  GetSnapshotViewRequest,
  GetTimeViewRequest,
  SummaryDto,
} from 'apis/backendApi';
import { addQueryContext, WithQueryContext } from 'core/apiQueryContext';

export const reportsApiDef = createApi({
  reducerPath: 'reportsApi',
  baseQuery: apiQuery(),

  endpoints: builder => ({
    getTimeView: builder.query<
      SummaryDto,
      WithQueryContext<GetTimeViewRequest>
    >({
      query: ({ buCode, reportname, date }) => {
        return {
          url: reportname && date ? '/reports/v1/{buCode}/timeview' : null,
          pathVariables: { buCode },
          params: {
            reportname,
            date,
          },
        };
      },
    }),

    getSnapshotView: builder.query<SummaryDto, GetSnapshotViewRequest>({
      query: ({ buCode, reportname, date }) => {
        return {
          url: reportname && date ? '/reports/v1/{buCode}/snapshotview' : null,
          pathVariables: { buCode },
          params: {
            reportname,
            date,
          },
        };
      },
    }),
  }),
});

const reportsApi = addQueryContext(reportsApiDef);
export const { useGetTimeViewQuery, useGetSnapshotViewQuery } = reportsApi;

export default reportsApi;
