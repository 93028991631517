import dayjs from 'dayjs';

/**
 * Formats a date in YYYYMMDD format
 * @param date a Date object
 * @returns the date in YYYYMMDD format
 */
export const getApiDate = (date: Date) => dayjs(date).format('YYYYMMDD');

/**
 * Formats a date in YYYY-MM-DD format
 * @param date a Date object
 * @returns the date in YYYY-MM-DD format
 */
export const getInputDate = (date: Date) => dayjs(date).format('YYYY-MM-DD');

/**
 * Converts a date from YYYYMMDD to YYYY-MM-DD format
 * @param apiDate a timezone-less date in YYYYMMDD format
 * @returns the date in YYYY-MM-DD format
 */
export const getInputDateFromApi = (apiDate: string) =>
  dayjs(apiDate).format('YYYY-MM-DD');

/**
 * Converts a date from YYYY-MM-DD to YYYYMMDD format
 * @param apiDate a timezone-less date in YYYY-MM-DD format
 * @returns the date in YYYYMMDD format
 */
export const getApiDateFromInput = (inputDate: string) =>
  dayjs(inputDate).format('YYYYMMDD');

/**
 * Parses a YYYYMMDD date to a Date object
 * @param apiDate a timezone-less date in YYYYMMDD format
 * @returns a Date object in the local timezone
 */
export const parseApiDate = (dateString: string) =>
  dayjs(dateString, 'YYYYMMDD').toDate();

/**
 * Outputs a timestamp in the format YYYYMMDD-HHmm or YYYYMMDD-HHmmss
 * @param date the date to be rendered
 * @param withSeconds whether to add the seconds to the timestamp
 * @returns the rendered timestamp
 */
export const timestamp = (date: Date, withSeconds: boolean = false) =>
  dayjs(date).format(`YYYYMMDD-HHmm${withSeconds ? 'ss' : ''}`);
