import { isCloudApiEnabled } from 'core/apiConfig';

type Environment = 'stage' | 'dev' | 'prod';

type FeatureFlag = {
  buCode: string | '*';
  environments: Environment[];
};

function getEnvironment(): Environment {
  const { hostname } = window.location;
  if (hostname === 'stage.slm.ingka.com') {
    return 'stage';
  } else if (hostname === 'dev.slm.ingka.com' || hostname === 'localhost') {
    return 'dev';
  } else {
    return 'prod';
  }
}

function isFeatureEnabled(buCode: string, featureFlags: FeatureFlag[]) {
  const environment = getEnvironment();

  return featureFlags.some(
    x =>
      (x.buCode === '*' || x.buCode === buCode) &&
      x.environments.includes(environment)
  );
}

const BULK_ASSIGNMENT: FeatureFlag[] = [
  { buCode: '*', environments: ['stage'] },
  { buCode: '*', environments: ['dev'] },
];

export function isBulkAssignmentEnabled(buCode: string) {
  return isCloudApiEnabled();
}

export function isMobileEnabled() {
  const environment = getEnvironment();
  return environment === 'stage' || environment === 'dev';
}
