import { createApi } from '@reduxjs/toolkit/query/react';
import { apiQuery } from 'core/rtkQuery';
import { CalculationMetaDataDto } from 'apis/backendApi';
import { addQueryContext, WithQueryContext } from 'core/apiQueryContext';

const utilityApiDef = createApi({
  reducerPath: 'utilityApi',
  baseQuery: apiQuery(),

  endpoints: builder => ({
    getCalculationsItemMetadata: builder.query<
      CalculationMetaDataDto,
      WithQueryContext<{ itemNo: string }>
    >({
      query: ({ buCode, itemNo }) => ({
        url: '/calculations/v1/{buCode}/metadata/{itemNo}',
        pathVariables: {
          buCode,
          itemNo,
        },
      }),
      keepUnusedDataFor: 0,
    }),

    postCalculationsTriggerItem: builder.mutation<
      void,
      WithQueryContext<{ itemNo: string; date?: string }>
    >({
      query: ({ buCode, itemNo, date }) => ({
        method: 'POST',
        url: '/calculations/v1/{buCode}/trigger/{itemNo}',
        pathVariables: {
          buCode,
          itemNo,
        },
        params: { date },
      }),
    }),

    getCalculationsStoreMetadata: builder.query<
      CalculationMetaDataDto,
      WithQueryContext<{}>
    >({
      query: ({ buCode }) => ({
        url: '/calculations/v1/{buCode}/metadata',
        pathVariables: {
          buCode,
        },
      }),
      keepUnusedDataFor: 0,
    }),

    postCalculationsTriggerStore: builder.mutation<
      void,
      WithQueryContext<{ date?: string }>
    >({
      query: ({ buCode, date }) => ({
        method: 'POST',
        url: '/calculations/v1/{buCode}/trigger',
        params: { date },
        pathVariables: {
          buCode,
        },
      }),
    }),

    postItemAssignmentMigration: builder.mutation<void, WithQueryContext<{}>>({
      query: ({ buCode }) => ({
        method: 'POST',
        url: '/items/v1/{buCode}/assignment-migration',
        params: { background: true },
        pathVariables: {
          buCode,
        },
      }),
    }),
  }),
});

const utilityApi = addQueryContext(utilityApiDef);

export const {
  useLazyGetCalculationsItemMetadataQuery,
  usePostCalculationsTriggerItemMutation,
  usePostCalculationsTriggerStoreMutation,
  useLazyGetCalculationsStoreMetadataQuery,
  usePostItemAssignmentMigrationMutation,
} = utilityApi;

export default utilityApi;
