import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'core/store';

/**
 * Maintains API query context state across Routes.
 */
export type QueryContextState = { buCode: string | null };

/**
 * Maintains API query context state across Routes.
 */
const apiQueryContextSlice = createSlice({
  name: 'apiQueryContext',
  initialState: {
    buCode: null,
  } as QueryContextState,

  reducers: {
    setBuCode(state, action: PayloadAction<Pick<QueryContextState, 'buCode'>>) {
      state.buCode = action.payload.buCode;
    },
  },
});

export const { setBuCode } = apiQueryContextSlice.actions;

export const selectApiQueryContext = (root: RootState) => root.apiQueryContext;

export default apiQueryContextSlice;
