import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import Loading, { LoadingBall } from '@ingka/loading';
import StatusTextBox from 'components/StatusTextBox';
import { useIntl } from 'react-intl';
import cm from 'core/commonMessages';
import { useAuth0 } from 'components/Auth';
import { setApiAccessToken } from 'core/apiConfig';

export type User = {
  country: string;
  shortName: string;
  fullName: string;
  userId: string;
  jobTitle: string;
};

const UserContext = createContext<User>(null);

enum AuthenticationState {
  AUTHENTICATION_PENDING,
  AUTHENTICATED,
  NOT_AUTHENTICATED,
}

const COMPLETE_AUTHENTICATION = Symbol();
const FAIL_AUTHENTICATION = Symbol();

type AuthenticationAction = {
  type: Symbol;
  payload?: { accessToken: string };
};

function useAuthenticationState() {
  return useReducer(
    (state: AuthenticationState, action: AuthenticationAction) => {
      const { type, payload } = action;
      switch (type) {
        case COMPLETE_AUTHENTICATION:
          state = AuthenticationState.AUTHENTICATED;
          setApiAccessToken(payload.accessToken);
          break;
        case FAIL_AUTHENTICATION:
          state = AuthenticationState.NOT_AUTHENTICATED;
          break;
      }
      return state;
    },
    AuthenticationState.AUTHENTICATION_PENDING
  );
}

/**
 * Provider that ensures all User data is available before rendering the application.
 */
export const UserProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const {
    isAuthenticated,
    isLoading: isLoadingUser,
    error,
    user: auth0User,
    getAccessTokenSilently,
  } = useAuth0();

  const [authenticationState, dispatch] = useAuthenticationState();

  useEffect(() => {
    if (isLoadingUser) {
      return;
    }

    if (isAuthenticated) {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently();
          dispatch({
            type: COMPLETE_AUTHENTICATION,
            payload: { accessToken },
          });
        } catch {
          dispatch({ type: FAIL_AUTHENTICATION });
        }
      })();
    } else {
      dispatch({ type: FAIL_AUTHENTICATION });
    }
  }, [isLoadingUser, isAuthenticated, dispatch, getAccessTokenSilently]);

  const user = useMemo<User>(() => {
    if (authenticationState === AuthenticationState.AUTHENTICATED) {
      const {
        name,
        'https://accounts.ikea.com/networkid': userId,
        'https://accounts.ikea.com/country': country,
        'https://accounts.ikea.com/jobtitle': jobTitle,
      } = auth0User;
      return {
        shortName: name.substring(0, name.indexOf(' (')) || name,
        fullName: name,
        userId,
        country,
        jobTitle,
      };
    } else {
      return null;
    }
  }, [auth0User, authenticationState]);

  const { $t } = useIntl();

  const isLoading =
    authenticationState === AuthenticationState.AUTHENTICATION_PENDING;

  return (
    <UserContext.Provider value={user}>
      {isLoading && !error && (
        <Loading text={$t(cm.authenticating)}>
          <LoadingBall />
        </Loading>
      )}
      {!isLoading && error && (
        <StatusTextBox
          title={$t(cm.error)}
          body={$t(cm.authenticationFailed)}
          type="ERROR"
        />
      )}
      {!isLoading && !error && children}
    </UserContext.Provider>
  );
};

/**
 * Obtains the User, depending on availability. The user is available after authentication and having obtained an
 * access token.
 *
 * This hook is intended for use cases that distinguish between a user being available or not. E.g. the landing page.
 */
export function useOptionalUser(): User | null {
  const user = useContext(UserContext);

  if (user === undefined) {
    throw new Error('This hook must be invoked within a UserContext.');
  }

  return user;
}
