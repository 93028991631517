import { createApi } from '@reduxjs/toolkit/query/react';
import { StoreMetaDataDto } from 'apis/backendApi';
import { addQueryContext, WithQueryContext } from 'core/apiQueryContext';
import { apiQuery } from 'core/rtkQuery';

const storeMetadataApiDef = createApi({
  reducerPath: 'storeMetadataApi',
  baseQuery: apiQuery(),

  endpoints: build => ({
    getMetadata: build.query<StoreMetaDataDto, WithQueryContext<{}>>({
      query: ({ buCode }) => {
        return {
          url: '/store/v1/{buCode}/metadata',
          pathVariables: { buCode },
        };
      },
      keepUnusedDataFor: 3600,
    }),
  }),
});

const storeMetadataApi = addQueryContext(storeMetadataApiDef);
export const { useGetMetadataQuery } = storeMetadataApi;

export default storeMetadataApi;
