import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import { IntlConfig, IntlProvider } from 'react-intl';
import { Status } from 'core/commonTypes';
import { findBestFitLocale } from './util';
import {
  ILanguageProviderContext,
  LanguageReducerAction,
  LanguageReducerState,
} from './types';
import { DEFAULT_INTL_CONFIG } from 'react-intl/src/utils';
import { setApiLanguage } from 'core/apiConfig';

const translationBaseUrl = '/translations';
const fetchTranslation = async locale => {
  const res = await fetch(`${translationBaseUrl}/${locale}.json`);
  if (res.status !== 200) {
    throw new Error('Unable to get translation');
  }
  return (await res.json()) as Record<string, string>;
};
const LOCAL_STORAGE_LANGUAGE_SETTING = 'SLM_USER_LOCALE';
const setLocalStorageLanguage = locale => {
  window.localStorage.setItem(LOCAL_STORAGE_LANGUAGE_SETTING, locale);
};
const getStorageLanguage: () => string | null = () => {
  return window.localStorage.getItem(LOCAL_STORAGE_LANGUAGE_SETTING);
};

const languageStateReducer = (
  state: LanguageReducerState,
  action: LanguageReducerAction
) => {
  switch (action.type) {
    case 'MESSAGES':
      return {
        ...state,
        messageStatus: Status.OK,
        error: null,
        messages: action.messages,
        locale: action.locale,
      };
    case 'ERROR':
      return {
        ...state,
        messageStatus: Status.ERROR,
        error: { error: action.error, locale: action.locale },
        messages: state.messages ?? {},
      };
    case 'STATUS':
      return { ...state, messageStatus: action.status };
    default:
      return state;
  }
};
const initialState = {
  locale: getStorageLanguage() || findBestFitLocale(window.navigator.language),
  messageStatus: Status.EMPTY,
  messages: null,
  error: null,
};

const LanguageProviderContext = createContext<ILanguageProviderContext | null>(
  null
);

const LanguageProvider = ({ children }: PropsWithChildren<any>) => {
  const [state, dispatch] = useReducer(languageStateReducer, initialState);

  const loadLocale = async (locale: string, activeChoice = false) => {
    try {
      dispatch({ type: 'STATUS', status: Status.LOADING });
      const messages = await fetchTranslation(locale);
      dispatch({ type: 'MESSAGES', messages, locale });
      setApiLanguage(locale);
      const fontLink = document.getElementById(
        'ikea-font-link'
      ) as HTMLLinkElement;
      fontLink.href = `https://www.ikea.com/global/assets/fonts/${
        locale.split('-')[0]
      }/fonts.css`;
      if (activeChoice) {
        setLocalStorageLanguage(locale);
      }
    } catch (e) {
      dispatch({ type: 'ERROR', error: e, locale });
    }
  };
  const setLocale = useCallback(locale => {
    loadLocale(locale, true).catch();
  }, []);

  //Initial load of messages
  useEffect(() => {
    if (!state.messages) {
      setApiLanguage(state.locale);
      loadLocale(state.locale).catch();
    }
  }, [state.locale, state.messages]);

  const onIntlError: IntlConfig['onError'] = err => {
    if (err.code !== 'MISSING_TRANSLATION') {
      DEFAULT_INTL_CONFIG.onError(err);
    }
  };

  return (
    <LanguageProviderContext.Provider value={{ ...state, setLocale }}>
      <IntlProvider
        locale={state.locale}
        defaultLocale={'en'}
        messages={state.messages || {}}
        onError={onIntlError}
      >
        {children}
      </IntlProvider>
    </LanguageProviderContext.Provider>
  );
};

export const useLanguageProvider = () => {
  const context = useContext(LanguageProviderContext);

  if (!context) {
    throw new Error('Missing LanguageProvider context');
  }

  return context;
};

export default LanguageProvider;
