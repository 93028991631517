import {
  TypedUseLazyQuery,
  TypedUseMutation,
  TypedUseQuery,
} from '@reduxjs/toolkit/dist/query/react';
import { useBuCode } from 'hooks/buCode';
import React from 'react';

/**
 * A mutation hook which has the {@link QueryContext} added to its query parameter
 */
type MutationHookWithContext<T> = T extends TypedUseMutation<
  infer ResultType,
  infer QueryArg,
  infer BaseQuery
>
  ? [QueryArg] extends [void]
    ? // is it a query arg
      TypedUseMutation<ResultType, void, BaseQuery>
    : TypedUseMutation<
        ResultType,
        Omit<QueryArg, keyof QueryContext>,
        BaseQuery
      >
  : never;

/**
 * A lazy query hook which has the {@link QueryContext} added to its query parameter
 */

type LazyQueryHookWithContext<T> = T extends TypedUseLazyQuery<
  infer ResultType,
  infer QueryArg,
  infer BaseQuery
>
  ? // is the QueryArg identical to QueryContext?
    QueryContext extends QueryArg
    ? // it is, so we remove it completely
      TypedUseLazyQuery<ResultType, void, BaseQuery>
    : // it is not, so we only remove the relevant context keys
      TypedUseLazyQuery<
        ResultType,
        Omit<QueryArg, keyof QueryContext>,
        BaseQuery
      >
  : never;

/**
 * An eager query hook which has the {@link QueryContext} added to its query parameter
 */

type QueryHookWithContext<T> = T extends TypedUseQuery<
  infer ResultType,
  infer QueryArg,
  infer BaseQuery
>
  ? // is the QueryArg identical to QueryContext?
    QueryContext extends QueryArg
    ? // it is, so we remove it completely
      TypedUseQuery<ResultType, void, BaseQuery>
    : // it is not, so we only remove the relevant context keys
      TypedUseQuery<ResultType, Omit<QueryArg, keyof QueryContext>, BaseQuery>
  : never;

type ApiWithInjectedQueryContext<T> = {
  // Is it a function?
  [K in keyof T]: T[K] extends (...args: any) => any
    ? // is it a lazy query hook?
      K extends `useLazy${string}Query`
      ? // It is a lazy query hook
        LazyQueryHookWithContext<T[K]>
      : // is it an eager query hook?
      K extends `use${string}Query`
      ? // It is a query hook
        QueryHookWithContext<T[K]>
      : // not a query hook. is it a mutation?
      K extends `use${string}Mutation`
      ? // It is a mutation
        MutationHookWithContext<T[K]>
      : // not a query hook or mutation, not touching it
        T[K]
    : // not a function, not touching it
      T[K];
};

/**
 * Context variables which can be useful inside the hook
 */
export type QueryContext = { buCode: string };

export type WithQueryContext<T extends object | void = {}> = QueryContext & T;

/**
 * Injects the keys inside of {@link QueryContext} into
 * the first parameter of the query, when called as a hook. i.e.
 * `useGetUserQuery` will also have the contents of QueryContext available at query time
 */
export function addQueryContext<T>(api: T) {
  const copy = { ...api };
  for (const [fieldName, oldHook] of Object.entries(copy)) {
    if (typeof oldHook !== 'function') continue;
    // handle lazy query hooks
    if (fieldName.match(/^useLazy[A-Z0-9][a-zA-Z0-9]+Query$/)) {
      const useNewLazyQueryHook = (...args: unknown[]) => {
        const buCode = useBuCode();

        const [lazyQuery, ...rest] = oldHook(...args);

        const newLazyQuery = React.useCallback(
          (firstArg?: object, ...args: unknown[]) => {
            return lazyQuery({ ...(firstArg || {}), buCode }, ...args);
          },
          [buCode, lazyQuery]
        );

        return [newLazyQuery, ...rest];
      };

      copy[fieldName] = useNewLazyQueryHook;
      // Handle eager query hooks
    } else if (fieldName.match(/^use[A-Z0-9][a-zA-Z0-9]+Query$/)) {
      const useNewQueryHook = (firstArg?: object, ...args: unknown[]) => {
        const buCode = useBuCode();

        return oldHook({ ...(firstArg || {}), buCode }, ...args);
      };

      copy[fieldName] = useNewQueryHook;

      // Handle mutation hooks
    } else if (fieldName.match(/^use[A-Z0-9][a-zA-Z0-9]+Mutation$/)) {
      const useNewMutationHook = (...args: unknown[]) => {
        const buCode = useBuCode();

        const [mutator, ...rest] = oldHook(...args);

        const newMutator = React.useCallback(
          (firstArg?: object, ...args: unknown[]) => {
            return mutator({ ...(firstArg || {}), buCode }, ...args);
          },
          [buCode, mutator]
        );

        return [newMutator, ...rest];
      };

      copy[fieldName] = useNewMutationHook;
    }
  }

  return copy as ApiWithInjectedQueryContext<T>;
}
