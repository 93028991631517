let apiRoot: string;

export const setApiRoot = (root: string) => (apiRoot = root);

export const getApiRoot = () => apiRoot;

let accessToken: string;

export const setApiAccessToken = (token: string) => (accessToken = token);

export const hasApiAccessToken = () => accessToken?.length > 0;

export const getApiAccessToken = () => accessToken;

let language: string = 'en-GB';

export const setApiLanguage = (locale: string) => (language = locale);

export const hasApiLanguage = () => language?.length > 0;

export const getApiLanguage = () => language;

let cloudApiEnabled = false;

export const setIsCloudApiEnabled = (enabled: boolean) => {
  cloudApiEnabled = enabled;
};

export const isCloudApiEnabled = () => cloudApiEnabled;
