import {
  AvailabilityInfo,
  GetItemSearchRequest,
  LocationType,
  MerchandisingSolution,
  PaginatedItemSearchResultDto,
  SearchActionFilterEnum,
  SearchArticleStatusEnum,
  SearchAvailabilityEnum,
  SearchFlowTypeEnum,
  SearchLifeCycleEnum,
  SearchProblemCodeEnum,
  SearchSalesSteeringEnum,
  SearchSortOrderEnum,
} from 'apis/backendApi';
import { MessageDescriptor } from 'react-intl';
import { getApiDate } from 'core/util/date';
import { PendingUpdateEntity } from 'core/redux/pendingUpdatesSlice';

export type SearchCriteria = {
  rangeStructureId: null | string;
  divisionId: null | string;
  specialityShopId: null | string;
  rangeGroupId: null | string;
  flowType: null | SearchFlowTypeEnum;
  status: null | SearchArticleStatusEnum;
  problemCode: null | SearchProblemCodeEnum;
  lifecycle: null | SearchLifeCycleEnum;
  availability: null | SearchAvailabilityEnum;
  salesSteering: null | SearchSalesSteeringEnum;
  actionFilter: null | SearchActionFilterEnum;
  exceedsExcessiveLimit: null | boolean;
  attributeDate: null | Date;
  text: null | string;
  sortType: null | SearchSortOrderEnum;
  pageNr: null | number;
  pageSize: null | number;
};

export function mapSearchCriteriaToQuery(
  criteria: SearchCriteria,
  buCode: string
): GetItemSearchRequest {
  return {
    buCode,
    flowType: criteria.flowType,
    status: criteria.status,
    lifeCycle: criteria.lifecycle,
    problemCode: criteria.problemCode,
    pageNr: criteria.pageNr,
    pageSize: criteria.pageSize,
    sort: criteria.sortType,
    divId: criteria.divisionId,
    spId: criteria.specialityShopId,
    rgId: criteria.rangeGroupId,
    rangeId: criteria.rangeStructureId,
    availabilityFilter: criteria.availability,
    salesSteering: criteria.salesSteering,
    actionFilter: criteria.actionFilter,
    exceedsExcessiveLimit: criteria.exceedsExcessiveLimit,
    date: getApiDate(criteria.attributeDate),
    text: criteria.text,
  };
}

export type CommonTableProps = {
  data: PaginatedItemSearchResultDto;
  summary?: {
    isLoading: boolean;
    data: { fillingRate: number } | undefined;
  };
};

export type MessageDescriptorWithEnum = MessageDescriptor & {
  enumValue?: string;
  hidden?: boolean;
};

export type AvinAndSalesInfoSet = {
  availability: AvailabilityInfo[];
  salesInfo: string;
  salesInfoFromDate: string;
};

export type Summary = {
  data: any;
  isLoading: boolean;
};

/**
 *  Get a LocationType enum value based on homebase and primary flag values
 */
export function getLocationType(homebase: boolean, primary: boolean) {
  if (homebase && primary) {
    return LocationType.HOMEBASE_PRIMARY;
  } else if (homebase) {
    return LocationType.HOMEBASE;
  } else if (primary) {
    return LocationType.PRIMARY;
  } else {
    return LocationType.NORMAL;
  }
}

export type DayAssignment = {
  fromDate: string;
  quantity: number;
  productDominance: number;
  merchandisingSolution: MerchandisingSolution;
  primary: boolean;
  homebase: boolean;
  manualPick: boolean;

  /**
   * Indicates if assignment is editable. This is false for dates in the past.
   */
  isEditable: boolean;

  /**
   * Indicates if the assignment has been explicitly set or if it is derived from an earlier assignment.
   */
  isExplicit: boolean;

  /**
   * Indicates if the assignment is modified. This is used for tracking updates in the UI.
   */
  isModified: boolean;

  /**
   * Indicates if the assignment was deleted. This maps to quantity of -1 from the backend.
   */
  isDeleted: boolean;

  /**
   * The last updated timestamp from the backend. Can be used to distinguish between Create and Update operations.
   */
  lastUpdated?: string;
};

export type WeekAssignments = {
  itemNo: string;
  weekNumber: number;
  slid: string;
  recommendedMerchandising: MerchandisingSolution;
  assignments: DayAssignment[];
};

export type PendingAssignment = {
  buCode: string;
  itemNo: string;
  slid: string;
  fromDate: string;
};

export function encodePendingAssignment(assignment: PendingAssignment) {
  const { buCode, itemNo, slid, fromDate } = assignment;
  if (
    typeof buCode !== 'string' ||
    typeof itemNo !== 'string' ||
    typeof slid !== 'string' ||
    typeof fromDate !== 'string'
  ) {
    throw new Error('Invalid arguments');
  }

  return `assignment:/${buCode}/${itemNo}/${slid}/${fromDate}`;
}

function decodePendingAssignment(id: string): PendingAssignment {
  const match = id.match(/^assignment:\/(\d+)\/(\d{8})\/(\w+)\/(\d{8})$/);
  if (match) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, buCode, itemNo, slid, fromDate] = match;
    if (
      typeof buCode !== 'string' ||
      typeof itemNo !== 'string' ||
      typeof slid !== 'string' ||
      typeof fromDate !== 'string'
    ) {
      return undefined;
    }

    return {
      buCode,
      itemNo,
      slid,
      fromDate,
    };
  }
}

export function findPendingAssignments(
  entities: PendingUpdateEntity[],
  buCode: string,
  itemNo: string,
  slid?: string
) {
  return entities
    .map(entity => decodePendingAssignment(entity.id))
    .filter(
      assignment =>
        assignment !== undefined &&
        assignment.buCode === buCode &&
        assignment.itemNo === itemNo &&
        (slid === undefined || assignment.slid === slid)
    );
}
