import { createApi } from '@reduxjs/toolkit/query/react';
import { apiQuery } from 'core/rtkQuery';
import { ActionsDto, ActionsSummaryDto } from 'apis/backendApi';
import { addQueryContext, WithQueryContext } from 'core/apiQueryContext';

const actionsApiDef = createApi({
  reducerPath: 'actionsApi',
  baseQuery: apiQuery(),

  endpoints: builder => ({
    getActionsSummary: builder.query<ActionsSummaryDto, WithQueryContext<{}>>({
      query: ({ buCode }) => ({
        url: '/actions/v1/{buCode}/summary',
        pathVariables: { buCode },
      }),
      // Summary is fetched explicitly, so does not need to be cached.
      keepUnusedDataFor: 0,
    }),

    getActions: builder.query<ActionsDto, WithQueryContext<{ divId: string }>>({
      query: ({ buCode, divId }) => ({
        url: '/actions/v1/{buCode}/division/{divId}',
        pathVariables: { buCode, divId },
      }),
    }),
  }),
});
export const actionsApi = addQueryContext(actionsApiDef);
export default actionsApi;

export const { useGetActionsSummaryQuery, useGetActionsQuery } = actionsApi;
