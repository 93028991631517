import { FormatNumberOptions, useIntl } from 'react-intl';
import { useCallback, useMemo } from 'react';

/**
 * Parses a string to a number using the given scale.
 *
 * @param text
 * @param scale The maximum number of digits after the decimal point. Any additional digits are cut off automatically.
 * @return The parsed number or `null` if the input could not be parsed or is otherwise considered invalid.
 */
export type ParseFn = (text: string, scale?: number) => number | null;

const NUMBER_PATTERN = /^-?(\d+)?\.?\d+$/;

/**
 * Produces a function for parsing strings to numbers, according to the current locale.
 *
 * The current implementation removes the thousand separator and only considers the decimal separator to be relevant.
 */
export function useParseNumber(): ParseFn {
  const { formatNumberToParts } = useIntl();

  const separators = useMemo(() => {
    const parts = formatNumberToParts(1000.1);
    const [thousand] = parts.filter(p => p.type === 'group').map(p => p.value);
    const [decimal] = parts.filter(p => p.type === 'decimal').map(p => p.value);

    return { decimal, thousand };
  }, [formatNumberToParts]);

  return useCallback(
    (input: string, scale: number = 3) => {
      const { decimal, thousand } = separators;
      input = input.trim().replaceAll(thousand, '').replaceAll(decimal, '.');

      if (!input.match(NUMBER_PATTERN)) {
        return null;
      }

      let value = Number.parseFloat(input);

      if (Number.isNaN(value)) {
        // Should not happen, but you never know what users are inputting.
        return null;
      }

      const a = Math.pow(10, scale);
      value = Math.floor(value * a) / a;

      return value;
    },
    [separators]
  );
}

/**
 * Wrapper for `formatNumber` that uses application-specific default options.
 */
export function useFormatNumber() {
  const { formatNumber } = useIntl();

  return useCallback(
    (value: number, options: FormatNumberOptions = {}) => {
      if (options.minimumFractionDigits === undefined) {
        if (Number.isInteger(value)) {
          options.minimumFractionDigits = 0;
        } else {
          options.minimumFractionDigits = 3;
        }
      }
      return formatNumber(value, {
        ...options,
      });
    },
    [formatNumber]
  );
}

/**
 * Replaces `number` values in the input object with formatted strings.
 *
 * The main use case is to provide forms with default values suitable for numeric input fields.
 */
export function useFormattedValues(values: { [key: string]: any }) {
  const formatNumber = useFormatNumber();

  return useMemo(() => {
    const entries = Object.entries(values).map(([name, value]) => {
      if (typeof value === 'number') {
        value = formatNumber(value);
      }
      return [name, value];
    });
    return Object.fromEntries(entries);
  }, [formatNumber, values]);
}
